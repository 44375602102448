import { Toolbar, Button, AppBar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ROUTE_IMPRINT, ROUTE_PRIVACY_POLICY, ROUTE_TERMS_AND_CONDITIONS } from "../res/routes";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface FooterMenuProps {
    contentWidth: number;
}

const useStyles = makeStyles<Theme, FooterMenuProps>((theme) => ({
    grow: {
        flex: "1 1 auto",
    },
    menuButton: {
        marginRight: theme.spacing(2),
        alignContent: "center",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    toolBar: (props) => ({
        maxWidth: props.contentWidth + 150,
        marginRight: "auto",
        marginLeft: "auto",
        width: "100%",
    }),
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
}));

export default (props: FooterMenuProps) => {
    const classes = useStyles(props);
    let location = useLocation();

    const menuItems = [
        { text: "Impressum", path: ROUTE_IMPRINT },
        { text: "Datenschutzerklärung", path: ROUTE_PRIVACY_POLICY },
        { text: "Geschäftsbedigungen", path: ROUTE_TERMS_AND_CONDITIONS },
    ];

    return (
        <div>
            <div className={classes.sectionDesktop}>
                <AppBar
                    className={classes.appBar}
                    position="relative"
                    color="secondary"

                >
                    <div className={classes.toolBar}>
                        <Toolbar>
                            {menuItems.map((item) => (
                                <Button
                                    key={item.path}
                                    component={Link}
                                    color="inherit"
                                    className={classes.menuButton}
                                    variant={
                                        location.pathname === item.path
                                            ? "outlined"
                                            : undefined
                                    }
                                    to={item.path}
                                >
                                    {item.text}
                                </Button>
                            ))}
                            <div className={classes.grow} />
                            <p style={{ color: "gray" }}>
                                Copyright © <b>SaniAlarm</b> {new Date().getFullYear()}.
                            </p>
                        </Toolbar>
                    </div>
                </AppBar>
            </div>
            <div className={classes.sectionMobile}>
                <AppBar
                    className={classes.appBar}
                    position="relative"
                    color="secondary"
                >
                    <div className={classes.toolBar}>
                        <Toolbar>
                            <Button
                                component={Link}
                                color="inherit"
                                variant={
                                    location.pathname === ROUTE_IMPRINT
                                        ? "outlined"
                                        : undefined
                                }
                                to={ROUTE_IMPRINT}
                            >
                                Impressum
                            </Button>
                            <div className={classes.grow} />
                            <Button
                                component={Link}
                                color="inherit"
                                variant={
                                    location.pathname === ROUTE_PRIVACY_POLICY
                                        ? "outlined"
                                        : undefined
                                }
                                to={ROUTE_PRIVACY_POLICY}
                            >
                                Datenschutzerklärung
                            </Button>
                        </Toolbar>
                        <Toolbar>
                            <div className={classes.grow} />
                            <Button
                                component={Link}
                                color="inherit"
                                variant={
                                    location.pathname === ROUTE_TERMS_AND_CONDITIONS
                                        ? "outlined"
                                        : undefined
                                }
                                to={ROUTE_TERMS_AND_CONDITIONS}
                            >
                                Geschäftsbedigungen
                            </Button>
                            <div className={classes.grow} />
                        </Toolbar>
                        <Toolbar variant="dense">
                            <div className={classes.grow} />
                            <p style={{ color: "#131313" }}>
                                Copyright © <b>SaniAlarm</b> {new Date().getFullYear()}.
                            </p>
                        </Toolbar>
                    </div>
                </AppBar>
            </div>
        </div>
    );
}
