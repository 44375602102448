import backgroundImage_webp from "../res/images/home_background_image.webp";
import logo_png from "../res/images/sanialarm.png";
import logo_webp from "../res/images/sanialarm.webp";
import appPreview_png from "../res/images/home_app_preview.png";
import appPreview_webp from "../res/images/home_app_preview.webp";
import appStoreBadge_svg from "../res/images/app_store_badge.svg";
import playStoreBadge_png from "../res/images/play_store_badge.png";
import webAppBadge_png from "../res/images/web_app_badge.png";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { ANDROID_STORE_LINK, IOS_STORE_LINK, WEB_APP_LINK } from "../res/strings";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { ImgWithFallback } from "../components/ImgWithFallback";
import Panel from "../components/Panel";
import PrimaryPanel from "../components/PrimaryPanel";
import BasePage from "./BasePage";

interface HomeProps {
    contentWidth: number;
}

const useStyles = makeStyles<Theme, HomeProps>((theme: Theme) => ({
    section1Background: {
        backgroundImage: `url(${backgroundImage_webp})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: 520,
        display: "flex",
        alignItems: "center",
    },
    section1: {
        width: "100%",
        textAlign: "center",
        paddingTop: 30,
        paddingBottom: 30,
        margin: "auto",
    },
    box: {
        [theme.breakpoints.up("sm")]: {
            padding: 30,
        },
        padding: 20,
        marginBottom: 30,
    },
    appButton: {
        height: 43,
        width: 144,
    },
    boxContent: (props) => ({
        maxWidth: props.contentWidth,
        marginLeft: "auto",
        marginRight: "auto",
    }),
    img: {
        margin: "auto",
        display: "block",
        maxWidth: "250px",
    },
    gridStretch: {
        width: "100%",
    },
}));

export default function Home(props: HomeProps) {
    const classes = useStyles(props);

    return (<div><div className={classes.section1Background}>
        <div className={`${classes.section1} ${classes.boxContent}`}>
            <ImgWithFallback
                alt="logo"
                src={logo_webp}
                fallback={logo_png}
                width="150"
                height="150"
            />
            <h1 style={{ color: "white" }}>
                Die App zur schnellen, sicheren und intelligenten Alarmierung und
                Koordination von Schulsanitätern
            </h1>
            <a href={ANDROID_STORE_LINK}>
                <img
                    className={classes.appButton}
                    alt="Jetzt bei Google Play"
                    src={playStoreBadge_png}
                />
            </a>
            <a href={IOS_STORE_LINK}>
                <img className={classes.appButton} alt="iosStore" src={appStoreBadge_svg} />
            </a>
            <a href={WEB_APP_LINK}>
                <img className={classes.appButton} alt="WebApp" src={webAppBadge_png} />
            </a>
        </div>
    </div>
        <Panel contentWidth={props.contentWidth}>
            <Grid container justifyContent="center" spacing={5} alignItems="center">
                <Grid item md={4}>
                    <ImgWithFallback
                        className={classes.img}
                        src={appPreview_webp}
                        fallback={appPreview_png}
                        alt="App Preview"
                    />
                </Grid>
                <Grid container item spacing={5} md={8}>
                    <Grid item sm={6}>
                        <Typography variant="h5" component="p">
                            Schnell
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Mit der App sind die Sanitäter immer zeitig an Ort und Stelle
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="h5" component="p">
                            Sicher
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Ihre Daten werden sicher auf unseren Servern in Deutschland gespeichert und
                            sind nur für Sie zugänglich
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="h5" component="p">
                            Intelligent
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Durch intelligente Weiteralarmierung wird sichergestellt, dass
                            immer jemand verfügbar ist
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="h5" component="p">
                            Preiswert
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Kostenlose 5 Benutzer zum Testen und für jeden weiteren Nutzer
                            1 Euro pro Jahr
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Panel>
        <PrimaryPanel contentWidth={props.contentWidth}>
            <Typography variant="h4" component="p" gutterBottom>
                Über Uns
            </Typography>
            <Grid container spacing={5}>
                <Grid item sm={6} className={classes.gridStretch}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="p">
                                Claudius Kienle
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                Projektleiter und Softwareentwickler
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={6} className={classes.gridStretch}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="p">
                                Vincent Cui
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                Systemadministrator
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </PrimaryPanel>
        <Panel contentWidth={props.contentWidth}>
            <Grid container spacing={5}>
                <Grid item sm={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Welches Problem wollen wir lösen?
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                Bei den meisten jetzigen Alarmierungsmethoden besteht das
                                Problem, dass sie nicht effizient genug oder zu teuer sind.
                                Zum Beispiel wird bei der Alarmierung über die
                                Schulsprechanlage die ganze Schule gestört, Funkgeräte sind
                                teuer und die Sanitäter können vergessen diese im
                                Sekräteriat abzuholen.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                Was wir bieten
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                Eine Alternative zur Alarmierung und Koordination von
                                Schulsanitätern die sowohl kostengünstig als auch effizient ist.
                                Die SaniAlarm App ermöglicht eine gezielte und schnelle Alarmierung von Sanitätern.
                                Per Rückmeldung bekommt der Alarmierte Live Auskunft, welche SanitäterInnen dem Einsatz zugesagt haben.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Panel></div>
    );
}
