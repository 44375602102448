import { ReactNode } from "react";
import { Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
    APP_ROUTE_PRIVACY_POLICY,
    APP_ROUTE_DOCUMENTATION,
    ROUTE_DOCUMENTATION,
    ROUTE_HOME,
    ROUTE_IMPRINT,
    ROUTE_PRIVACY_POLICY,
    ROUTE_FAQ,
    ROUTE_PRICING,
    ROUTE_PRIVACY_POLICY_APP,
    ROUTE_TERMS_AND_CONDITIONS,
} from "../res/routes";
import Home from "./Home";
import Documentation from "./Documentation";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider, Routes } from "react-router-dom";
import Imprint from "./Imprint";
import PrivacyPolicy from "./PrivacyPolicy";
import MainMenu from "../components/MainMenu";
import FooterMenu from "../components/FooterMenu";
import FAQ from "./FAQ";
import Pricing from "./Pricing";
import PrivacyPolicyApp from "./PrivacyPolicyApp";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import TermsAndConditions from "./TermsAndConditions";

const useStyles = makeStyles(() => ({
    body: {
        height: "100vh",
        display: "flex",
        flexDirection: "column",
    },
    content: {
        flexGrow: 1,
    },
    bars: {
        flexShrink: 0,
    },
}));

function FirebaseAnalytics() {
    let location = useLocation();
    useEffect(() => {
        const page_path = location.pathname + location.search;
        logEvent(getAnalytics(), "screen_view", {
            firebase_screen: page_path,
            firebase_screen_class: undefined
        });
    }, [location]);
    return null;
}

interface BasePageProps {
    contentWidth: number,
    children: ReactNode;
}

export default function BasePage(props: BasePageProps) {
    const classes = useStyles();

    return <div className={classes.body}>
        <FirebaseAnalytics />
        <header className={classes.bars}>
            <MainMenu contentWidth={props.contentWidth} />
        </header>
        <main className={classes.content}>
            <Toolbar />
            {props.children}
        </main>
        <footer className={classes.bars}>
            <FooterMenu contentWidth={props.contentWidth} />
        </footer>
    </div>
}