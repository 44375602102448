import Panel from "../components/Panel"
import { Grid, Card, CardHeader, CardContent, Box, Typography } from "@mui/material"

interface PricingProps {
  contentWidth: number;
}

const tiers = [
  {
    title: 'Ersten 5 Geräte',
    price: '0',
    description: 'In jeder Gruppe sind die ersten 5 Geräte kostenlos für ein Jahr enthalten.',
  },
  {
    title: 'Jedes weitere Gerät',
    price: '1',
    description: 'Jedes weitere Gerät kostet 1 € Pro Jahr. Es können unbegrenzt viele Geräte gekauft werden.',
  },
];

const Pricing = (props: PricingProps) => {
  return (
    <div>
      <Panel contentWidth={props.contentWidth}>
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              md={6}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.light
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {tier.price}€
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /Jahr
                    </Typography>
                  </Box>
                  <ul style={{ listStyle: 'none' }}>
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                    >
                      {tier.description}
                    </Typography>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Panel>
    </div>
  )
};

export default Pricing;