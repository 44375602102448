import React from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { CONTACT_EMAIL, CONTACT_PHONE_NUMBER } from "../res/strings";
import { ROUTE_PRIVACY_POLICY_APP } from "../res/routes";
import { Link } from "react-router-dom";

interface PrivacyPolicyProps {
    contentWidth: number;
}

const useStyles = makeStyles<Theme, PrivacyPolicyProps>(() => ({
    box: {
        padding: 30,
        marginBottom: 30,
    },
    boxContent: (props) => ({
        maxWidth: props.contentWidth,
        marginLeft: "auto",
        marginRight: "auto",
    }),
}));

export default function PrivacyPolicy(props: PrivacyPolicyProps) {
    const classes = useStyles(props);
    return (
        <Box className={classes.box}>
            <div className={classes.boxContent}>
                <Typography variant="h4">Datenschutzerklärung</Typography>
                <Typography variant="body1" gutterBottom>
                    Die Datenschutzerklärung für die App finden Sie <Link to={ROUTE_PRIVACY_POLICY_APP}>Hier</Link>
                </Typography>
                <ol>
                    <li>
                        Datenschutz auf einen Blick
                        <Typography variant="h6">Allgemeine Hinweise</Typography>
                        <Typography variant="body2" gutterBottom>
                            Die folgenden Hinweise geben einen einfachen Überblick darüber,
                            was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
                            Website besuchen. Personenbezogene Daten sind alle Daten, mit
                            denen Sie persönlich identifiziert werden können. Ausführliche
                            Informationen zum Thema Datenschutz entnehmen Sie unserer unter
                            diesem Text aufgeführten Datenschutzerklärung.
                        </Typography>
                        <Typography variant="h6">
                            Datenerfassung auf dieser Webseite
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <b>
                                Wer ist verantwortlich für die Datenerfassung auf dieser
                                Website?
                            </b>
                            <br />
                            Die Datenverarbeitung auf dieser Website erfolgt durch den
                            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
                            dieser Website entnehmen.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <b>Wie erfassen wir Ihre Daten?</b>
                            <br />
                            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie
                            in ein Kontaktformular eingeben.
                            <br />
                            Andere Daten werden automatisch beim Besuch der Website durch
                            unsere IT-Systeme erfasst. Das sind vor allem technische Daten
                            (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
                            Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
                            sobald Sie diese Website betreten.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <b>Wofür nutzen wir Ihre Daten?</b>
                            <br />
                            Ein Teil der Daten wird erhoben, um eine fehlerfreie
                            Bereitstellung der Website zu gewährleisten. Andere Daten können
                            zur Analyse Ihres Nutzerverhaltens verwendet werden.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <b>Welche Rechte haben Sie bezüglich Ihrer Daten?</b>
                            <br />
                            Sie haben jederzeit das Recht unentgeltlich Auskunft über
                            Herkunft, Empfänger und Zweck Ihrer gespeicherten
                            personenbezogenen Daten zu erhalten. Sie haben außerdem ein
                            Recht, die Berichtigung oder Löschung dieser Daten zu verlangen.
                            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
                            sich jederzeit unter der im Impressum angegebenen Adresse an uns
                            wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der
                            zuständigen Aufsichtsbehörde zu.
                            <br />
                            Außerdem haben Sie das Recht, unter bestimmten Umständen die
                            Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                            verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung
                            unter „Recht auf Einschränkung der Verarbeitung“.
                        </Typography>
                        <Typography variant="h6">
                            Analyse-Tools und Tools von Drittanbietern
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
                            ausgewertet werden. Das geschieht vor allem mit Cookies und mit
                            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
                            erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu
                            Ihnen zurückverfolgt werden.
                            <br />
                            Sie können dieser Analyse widersprechen oder sie durch die
                            Nichtbenutzung bestimmter Tools verhindern. Detaillierte
                            Informationen zu diesen Tools und über Ihre
                            Widerspruchsmöglichkeiten finden Sie in der folgenden
                            Datenschutzerklärung.
                        </Typography>
                    </li>
                    <li>
                        Hosting und Content Delivery Networks (CDN)
                        <Typography variant="h6">Externes Hosting</Typography>
                        <Typography variant="body2" gutterBottom>
                            Diese Website wird bei einem externen Dienstleister gehostet
                            (Hoster). Die personenbezogenen Daten, die auf dieser Website
                            erfasst werden, werden auf den Servern des Hosters gespeichert.
                            Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen,
                            Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
                            Namen, Webseitenzugriffe und sonstige Daten, die über eine
                            Website generiert werden, handeln.
                            <br />
                            Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
                            gegenüber unseren potenziellen und bestehenden Kunden (Art. 6
                            Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen
                            und effizienten Bereitstellung unseres Online-Angebots durch
                            einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
                            <br />
                            Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
                            zur Erfüllung seiner Leistungspflichten erforderlich ist und
                            unsere Weisungen in Bezug auf diese Daten befolgen.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <b>Abschluss eines Vertrages über Auftragsverarbeitung</b>
                            <br />
                            Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben
                            wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster
                            geschlossen.
                        </Typography>
                    </li>
                    <li>
                        Allgemeine Hinweise und Pflichtinformationen
                        <Typography variant="h6">Datenschutz</Typography>
                        <Typography variant="body2" gutterBottom>
                            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
                            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
                            vertraulich und entsprechend der gesetzlichen
                            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                            <br />
                            Wenn Sie diese Website benutzen, werden verschiedene
                            personenbezogene Daten erhoben. Personenbezogene Daten sind
                            Daten, mit denen Sie persönlich identifiziert werden können. Die
                            vorliegende Datenschutzerklärung erläutert, welche Daten wir
                            erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
                            welchem Zweck das geschieht.
                            <br />
                            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
                            B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
                            kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                            Dritte ist nicht möglich.
                        </Typography>
                        <Typography variant="h6">
                            Hinweis zur verantwortlichen Stelle
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                            Website ist:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Claudius Kienle
                            <br />
                            Schneidemühler Str. 26a
                            <br />
                            76139 Karlsruhe
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Telefon: {CONTACT_PHONE_NUMBER}
                            <br />
                            E-Mail: {CONTACT_EMAIL}
                        </Typography>
                        <Typography variant="body2">
                            Verantwortliche Stelle ist die natürliche oder juristische
                            Person, die allein oder gemeinsam mit anderen über die Zwecke
                            und Mittel der Verarbeitung von personenbezogenen Daten (z. B.
                            Namen, E-Mail-Adressen o. Ä.) entscheidet.
                        </Typography>
                        <Typography variant="h6">
                            Widerruf Ihrer Einwilligung zur Datenverarbeitung
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                            ausdrücklichen Einwilligung möglich. Sie können eine bereits
                            erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
                            formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der
                            bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
                            unberührt.
                        </Typography>
                        <Typography variant="h6">
                            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
                            sowie gegen Direktwerbung (Art. 21 DSGVO)
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT.
                            E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
                            GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN
                            DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
                            EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
                            GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN
                            EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
                            DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
                            IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN,
                            ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
                            VERARBEITUNG N ACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
                            FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
                            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
                            (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                            <br />
                            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
                            DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT
                            WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
                            PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                            EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT
                            SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                            WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                            NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                            NACH ART. 21 ABS. 2 DSGVO).
                        </Typography>
                        <Typography variant="h6">
                            Beschwerderecht bei der zuständigen Aufsichtsbehörde
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
                            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
                            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
                            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das
                            Beschwerderecht besteht unbeschadet anderweitiger
                            verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                        </Typography>
                        <Typography variant="h6">
                            Recht auf Datenübertragbarkeit
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                            Einwilligung oder in Erfüllung eines Vertrags automatisiert
                            verarbeiten, an sich oder an einen Dritten in einem gängigen,
                            maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
                            direkte Übertragung der Daten an einen anderen Verantwortlichen
                            verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                        </Typography>
                        <Typography variant="h6">
                            SSL- bzw. TLS-Verschlüsselung
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
                            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
                            SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
                            erkennen Sie daran, dass die Adresszeile des Browsers von
                            „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
                            Ihrer Browserzeile.
                            <br />
                            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                            werden.
                        </Typography>
                        <Typography variant="h6">
                            Auskunft, Löschung und Berichtigung
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
                            gespeicherten personenbezogenen Daten, deren Herkunft und
                            Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
                            auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
                            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
                            jederzeit unter der im Impressum angegebenen Adresse an uns
                            wenden.
                        </Typography>
                        <Typography variant="h6">
                            Recht auf Einschränkung der Verarbeitung
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
                            jederzeit unter der im Impressum angegebenen Adresse an uns
                            wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
                            folgenden Fällen:
                        </Typography>
                        <ul>
                            <li>
                                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                                personenbezogenen Daten bestreiten, benötigen wir in der
                                Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung
                                haben Sie das Recht, die Einschränkung der Verarbeitung
                                Ihrer personenbezogenen Daten zu verlangen.
                            </li>
                            <li>
                                Wenn die Verarbeitung Ihrer personenbezogenen Daten
                                unrechtmäßig geschah/geschieht, können Sie statt der
                                Löschung die Einschränkung der Datenverarbeitung verlangen.
                            </li>
                            <li>
                                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen,
                                Sie sie jedoch zur Ausübung, Verteidigung oder
                                Geltendmachung von Rechtsansprüchen benötigen, haben Sie das
                                Recht, statt der Löschung die Einschränkung der Verarbeitung
                                Ihrer personenbezogenen Daten zu verlangen.
                            </li>
                            <li>
                                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
                                eingelegt haben, muss eine Abwägung zwischen Ihren und
                                unseren Interessen vorgenommen werden. Solange noch nicht
                                feststeht, wessen Interessen überwiegen, haben Sie das
                                Recht, die Einschränkung der Verarbeitung Ihrer
                                personenbezogenen Daten zu verlangen.
                            </li>
                        </ul>
                        <Typography variant="body2">
                            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
                            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
                            der Rechte einer anderen natürlichen oder juristischen Person
                            oder aus Gründen eines wichtigen öffentlichen Interesses der
                            Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
                        </Typography>
                        <Typography variant="h6">
                            Widerspruch gegen Werbe-E-Mails
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
                            Kontaktdaten zur Übersendung von nicht ausdrücklich
                            angeforderter Werbung und Informationsmaterialien wird hiermit
                            widersprochen. Die Betreiber der Seiten behalten sich
                            ausdrücklich rechtliche Schritte im Falle der unverlangten
                            Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                        </Typography>
                    </li>
                    <li>
                        Datenerfassung auf dieser Website
                        <Typography variant="h6">Cookies</Typography>
                        <Typography variant="body2" gutterBottom>
                            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies
                            sind kleine Textdateien und richten auf Ihrem Endgerät keinen
                            Schaden an. Sie werden entweder vorübergehend für die Dauer
                            einer Sitzung (Session-Cookies) oder dauerhaft (permanente
                            Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden
                            nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies
                            bleiben auf Ihrem Endgerät gespeichert bis Sie diese selbst
                            löschen oder eine automatische Lösung durch Ihren Webbrowser
                            erfolgt.
                            <br />
                            Teilweise können auch Cookies von Drittunternehmen auf Ihrem
                            Endgerät gespeichert werden, wenn Sie unsere Seite betreten
                            (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die
                            Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
                            Cookies zur Abwicklung von Zahlungsdienstleistungen).
                            <br />
                            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
                            technisch notwendig, da bestimmte Webseitenfunktionen ohne diese
                            nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
                            Anzeige von Videos). Andere Cookies dienen dazu das
                            Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
                            <br />
                            Cookies, die zur Durchführung des elektronischen
                            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von
                            Ihnen erwünschter Funktionen (z. B. Warenkorbfunktion)
                            erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f
                            DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes
                            Interesse an der Speicherung von Cookies zur technisch
                            fehlerfreien und optimierten Bereitstellung seiner Dienste.
                            Sofern eine entsprechende Einwilligung abgefragt wurde (z. B.
                            eine Einwilligung zur Speicherung von Cookies), erfolgt die
                            Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit.
                            a DSGVO; die Einwilligung ist jederzeit widerrufbar.
                            <br />
                            Sie können Ihren Browser so einstellen, dass Sie über das Setzen
                            von Cookies informiert werden und Cookies nur im Einzelfall
                            erlauben, die Annahme von Cookies für bestimmte Fälle oder
                            generell ausschließen sowie das automatische Löschen der Cookies
                            beim Schließen des Browsers aktivieren. Bei der Deaktivierung
                            von Cookies kann die Funktionalität dieser Website eingeschränkt
                            sein.
                            <br />
                            Soweit Cookies von Drittunternehmen oder zu Analysezwecken
                            eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
                            Datenschutzerklärung gesondert informieren und ggf. eine
                            Einwilligung abfragen.
                        </Typography>
                        <Typography variant="h6">Server-Log-Dateien</Typography>
                        <Typography variant="body2">
                            Der Provider der Seiten erhebt und speichert automatisch
                            Informationen in so genannten Server-Log-Dateien, die Ihr
                            Browser automatisch an uns übermittelt. Dies sind:
                        </Typography>
                        <ul>
                            <li>Browsertyp und Browserversion</li>
                            <li>verwendetes Betriebssystem</li>
                            <li>Referrer URL</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Uhrzeit der Serveranfrage</li>
                            <li>IP-Adresse</li>
                        </ul>
                        <Typography variant="body2" gutterBottom>
                            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
                            nicht vorgenommen.
                            <br />
                            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
                            1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                            Interesse an der technisch fehlerfreien Darstellung und der
                            Optimierung seiner Website – hierzu müssen die Server-Log-Files
                            erfasst werden.
                            <br />
                        </Typography>
                        <Typography variant="h6">
                            Anfrage per E-Mail, Telefon oder Telefax
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
                            Ihre Anfrage inklusive aller daraus hervorgehenden
                            personenbezogenen Daten (Name, Anfrage) zum Zwecke der
                            Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet.
                            Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                            <br />
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                            Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
                            Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
                            Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
                            Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
                            und/oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit.
                            f DSGVO), da wir ein berechtigtes Interesse an der effektiven
                            Bearbeitung der an uns gerichteten Anfragen haben.
                            <br />
                            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
                            verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
                            Einwilligung zur Speicherung widerrufen oder der Zweck für die
                            Datenspeicherung entfällt (z. B. nach abgeschlossener
                            Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
                            – insbesondere gesetzliche Aufbewahrungsfristen – bleiben
                            unberührt.
                            <br />
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="h6">Newsletterdaten</Typography>
                        <Typography variant="body2" gutterBottom>
                            Wenn Sie den auf der Website angebotenen Newsletter beziehen
                            möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
                            Informationen, welche uns die Überprüfung gestatten, dass Sie
                            der Inhaber der angegebenen E-Mail-Adresse sind und mit dem
                            Empfang des Newsletters einverstanden sind. Weitere Daten werden
                            nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten
                            verwenden wir ausschließlich für den Versand der angeforderten
                            Informationen und geben diese nicht an Dritte weiter.
                            <br />
                            Die Verarbeitung der in das Newsletteranmeldeformular
                            eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer
                            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte
                            Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie
                            deren Nutzung zum Versand des Newsletters können Sie jederzeit
                            widerrufen, etwa über den „Austragen“-Link im Newsletter. Die
                            Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
                            bleibt vom Widerruf unberührt.
                            <br />
                            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
                            hinterlegten Daten werden von uns bis zu Ihrer Austragung aus
                            dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter
                            gespeichert und nach der Abbestellung des Newsletters aus der
                            Newsletterverteilerliste gelöscht. Daten, die zu anderen Zwecken
                            bei uns gespeichert wurden bleiben hiervon unberührt.
                            <br />
                            Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
                            E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf.
                            in einer Blacklist gespeichert, um künftige Mailings zu
                            verhindern. Die Daten aus der Blacklist werden nur für diesen
                            Zweck verwendet und nicht mit anderen Daten zusammengeführt.
                            Dies dient sowohl Ihrem Interesse als auch unserem Interesse an
                            der Einhaltung der gesetzlichen Vorgaben beim Versand von
                            Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1
                            lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich
                            nicht befristet.
                        </Typography>
                    </li>
                </ol>
                <Typography variant="body1">
                    Quelle:
                    <br />
                    <a href="https://www.e-recht24.de/muster-datenschutzerklaerung.html">
                        https://www.e-recht24.de/muster-datenschutzerklaerung.html
                    </a>
                </Typography>
            </div>
        </Box >
    );
}
