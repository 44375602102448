import * as React from 'react';
import { Box, Typography, Button } from '@mui/material';

const NotFoundPage = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%',  }}>
      <Typography variant="h1" sx={{ fontWeight: 'bold', mb: 4 }}>404</Typography>
      <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 'bold', mb: 2 }}>Oops! Seite nicht gefunden</Typography>
      <Typography variant="subtitle1" sx={{ textAlign: 'center', mb: 4 }}>Es tut uns leid, aber die angeforderte Seite konnte nicht gefunden werden.</Typography>
      <Button variant="contained" href="/" sx={{ fontWeight: 'bold' }}>Zur Startseite</Button>
    </Box>
  );
};

export default NotFoundPage;
