import { ExpandMore } from "@mui/icons-material"
import {
  Accordion, AccordionSummary,
  AccordionDetails
} from "@mui/material"
import { ReactNode } from "react"

export interface AccordionItemProps {
  summary: ReactNode,
  details: ReactNode
}

const AccordionItem = ({ summary, details }: AccordionItemProps) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>
        {details}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionItem