import { Box, Grid, Link, Theme, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import faqUserDetailsNotRegistered from "../res/images/faq_user_details_not_registered.png";
import Panel from "../components/Panel";
import PrimaryPanel from "../components/PrimaryPanel";
import AccordionItem from "../components/AccordionItem";
import { CONTACT_EMAIL } from '../res/strings';
import { ImgWithFallback } from '../components/ImgWithFallback';

interface FAQProps {
    contentWidth: number;
}

const useStyles = makeStyles<Theme, FAQProps>((theme) => ({
    img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%",
    },
}));

export default function FAQ(props: FAQProps) {
    const classes = useStyles(props);
    return (
        <div>
            <Panel contentWidth={props.contentWidth}>
                <Typography variant="h3">Gruppe einrichten</Typography>
                <AccordionItem
                    summary={
                        <Typography>
                            Nachdem ich eine Gruppe erstelle, bin ich dann der Administrator der Gruppe?
                        </Typography>
                    }
                    details={
                        <div>
                            <Typography>
                                Wenn Sie eine Gruppe erstellen, sind Sie der Admin, korrekt.
                                Sie können dann weitere Nutzer über dessen E-Mail Adresse in der App hinzufügen,
                                sodass diese dann eine Mail erhalten,
                                mit der sie ihr Passwort zum Start setzen können
                                und sich dann mit E-Mail und Passwort in der App anmelden können.
                                Jeder Nutzer kann dabei einer folgender drei Rollen zugeordnet werden:
                            </Typography>
                            <ul>
                                <li><b>Administratoren:</b> Haben vollen Zugriff auf die Gruppe</li>
                                <li><b>Sender:</b> Können ausschließlich Alarme senden</li>
                                <li><b>Sanitäter:</b> Können sowohl Alarme senden, als auch Alarme empfangen</li>
                            </ul>
                        </div>
                    }
                />
                <AccordionItem
                    summary={
                        <Typography>
                            Was sollte getan werden, wenn sich ein Nutzer noch nicht registriert hat?
                        </Typography>
                    }
                    details={
                        <div>
                            <Grid container>
                                <Grid xs={8} item>
                                    <p><Typography>
                                        Falls sich ein erstellter Nutzer noch nicht registriert hat,
                                        sehen Sie in den Nutzerdetails des entsprechenden Nutzers einen Warnhinweis (siehe Bild).
                                    </Typography></p>
                                    <Typography>
                                        Der Nutzer sollte eine E-Mail erhalten haben, mit der er ein Passwort setzen kann.
                                        Sollte eine solche E-Mail noch nicht angekommen sein, versichern Sie sich, dass die
                                        hinterlegte E-Mail korrekt ist. Sie können über "Passwort-Reset E-Mail senden" eine neue
                                        E-Mail an den Nutzer verschicken.
                                        Falls die angegebene E-Mail nicht korrekt ist, können Sie den Nutzer löschen
                                        und mit der korrekten E-Mail neue erstellen.
                                    </Typography>

                                </Grid>
                                <Grid xs={4} item>
                                    <ImgWithFallback
                                        className={classes.img}
                                        src={faqUserDetailsNotRegistered}
                                        // fallback={item.fallbackImage}
                                        alt={""}
                                    /></Grid></Grid>
                        </div>
                    }
                />
                <AccordionItem
                    summary={
                        <Typography>
                            Wird zwischen Schüler und Lehrer unterschieden?
                        </Typography>
                    }
                    details={
                        <Typography>
                            Im Allgemeinen werden Schüler, die Schulsanitäter sind,
                            in der Rolle Sanitäter hinterlegt.
                            Dadurch können Sie Alarme senden, hauptsächlich empfangen Sie jedoch Alarme,
                            da Sie ja alarmiert werden sollen.
                            Es ist möglich Lehrern, sowie weiteren Schülern (z.B. Klassensprechern) einen Account zu erstellen,
                            die der Rolle <em>Sender</em> zugeordnet sind.
                            In dieser können Sie <b>ausschließlich</b> Alarme senden und haben sonst keinen Zugriff auf die Gruppe.
                        </Typography>
                    }
                />
                <AccordionItem
                    summary={
                        <Typography>
                            Kann ich als Administrator hinzugefügte Schüler auch verwalten? Also löschen oder stoppen?
                        </Typography>
                    }
                    details={
                        <Typography>
                            Jeder Schüler und Lehrer erhält einen eigenen Nutzer in der App
                            und ist mit seiner E-Mail Adresse und seinem Namen zugeordnet.
                            Wenn ein Alarm gesendet wird,
                            können Sie als Administrator in der Alarmhistorie nachsehen welcher Nutzer den Alarm gesendet hat.
                            Die Nutzer können Sie löschen oder in eine andere der drei Rollen verschieben.
                            Ein pausieren der Nutzer ist aktuell nicht möglich.
                        </Typography>
                    }
                />
            </Panel>
            <PrimaryPanel contentWidth={props.contentWidth}>
                <Typography variant="h3">Nutzerverwaltung</Typography>
                <AccordionItem
                    summary={
                        <Typography>
                            Wie viel kostet die App?
                        </Typography>
                    }
                    details={
                        <Typography>
                            Wenn Sie die App installieren und eine Gruppe erstellen,
                            fallen keine Kosten an.
                            Sie können in der Gruppe kostenlos bis zu 5 Nutzer hinzufügen.
                            Benötigen Sie mehr Nutzer,
                            können Sie weitere Gerätelizenzen kaufen.
                            Jede weitere Gerätelizenz kostet 1 Euro pro Jahr.
                            Weitere Nutzer können Sie einerseits in der App per Kreditkarte oder PayPal kaufen,
                            andererseits per Rechnung kaufen, indem sie eine E-Mail
                            an <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link> schicken.
                        </Typography>
                    }
                />
                <AccordionItem
                    summary={
                        <Typography>
                            Ist es möglich, das Limit für die zeitgleichen Anmeldungen eines Accounts zu erhöhen?
                        </Typography>
                    }
                    details={
                        <Box>
                            <Typography>
                                Es ist möglich mehrere Geräte auf einem Account anzumelden.
                                Hierfür müssen Sie in der SaniAlarm App einen Nutzer in der Nutzerliste auswählen.
                                In der sich öffnenden Übersicht können Sie dann unter "Maximale Geräte" die Anzahl an Geräte angeben,
                                auf die sich der ensprechende Nutzer gleichzeitig anmelden darf.
                                Bei eine Alarmierung werden alle Geräte des Nutzers gleichzeitig alarmiert und stehts synchronisiert.
                                Das heißt, es reicht wenn der Nutzer auf einem seiner Geräte den Alarm annimmt/ablehnt.
                            </Typography>
                        </Box>
                    }
                />
                <AccordionItem
                    summary={
                        <Typography>
                            Kann ich neue Nutzer auf Rechnung kaufen?
                        </Typography>
                    }
                    details={
                        <div>
                            <Typography>
                                Das ist kein Problem!
                                Sobald Sie Nutzer kaufen möchten,
                                schreiben Sie einfach eine E-Mail an <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>
                                mit folgenden Details:
                            </Typography>
                            <ul>
                                <li>Wie viele Nutzer möchten Sie kaufen?</li>
                                <li>Für wieviele Monate sollen die Nutzer in der App gekauft werden?</li>
                                <li>An welche Rechnungsadresse soll die Rechnung gerichtet sein?</li>
                                <li>In welcher Gruppe sollen die Nutzer hinzugefügt werden?</li>
                            </ul>
                            <b>Schicken Sie die Anfrage bitte über die E-Mail Adresse,
                                mit der Sie auch in der Gruppe registriert sind!</b>
                        </div>
                    }
                />
            </PrimaryPanel>
            <Panel contentWidth={props.contentWidth}>
                <Typography variant="h3">Alarmierung</Typography>
                <AccordionItem
                    summary={
                        <Typography>
                            Wie kann das Sekretariat alarmieren, die ja die App nicht haben?
                        </Typography>
                    }
                    details={
                        <Typography>
                            Das ist korrekt, das Sekreteriat hat die App im allgemeinen nicht, da sie am Computer arbeiten.
                            Hierfür gibt es die Möglichkeit, dass sich der Nutzer, der dem Sekreteriat zugeordnet ist,
                            über die entsprechende WebApp anmeldet.
                            Diese ist unter der Adresse: <Link href="https://web.sanialarm.de">https://web.sanialarm.de</Link> erreichbar.
                            über die WebApp kann das Sekreteriat, wie auch über die App, Alarme senden
                        </Typography>
                    }
                />
                <AccordionItem
                    summary={
                        <Typography>
                            Erfolgt die Alarmierung auch ohne Netz?
                        </Typography>
                    }
                    details={
                        <Typography>
                            Die Alarmierung erfolgt leider ausschließlich über eine Internetverbindung.
                            Die Mobilfunkgeräte müssen somit mit einem WLAN oder dem mobilen Netz verbunden sein.
                            In den meisten fällen reicht hierbei jedoch auch eine geringe Datengeschwindigkeit aus,
                            um Alarme empfangen zu können.
                            Jedoch können Alarme dann mit etwas Verzögerung eintreffen.
                        </Typography>
                    }
                />
                <AccordionItem
                    summary={
                        <Typography>
                            Werden bei einem Alarm immer alle informiert?
                        </Typography>
                    }
                    details={
                        <div>
                            <Typography>
                                Nein. Sie können Alarme nach drei verschiedenen Arten versenden:
                            </Typography>
                            <ul>
                                <li><b>An Alle:</b> Der Alarm geht an alle Sanitäter, sowie Administratoren, die in den Einstellungen die Option
                                    <em>Alarm An Alle</em> aktiviert haben.
                                </li>
                                <li><b>Nach Dienstplan:</b> Sie können einen Dienstplan erstellen (wie einen Stundenplan), auf den Sie die
                                    Sanitäter und Administratoren einteilen. Wird ein Alarm nach Dienstplan versendet,
                                    geht der Alarm an alle Sanitäter,
                                    die in den aktuell aktiven Dienstplänen eingetragen sind.
                                    Hier gibt es außerdem die Möglichkeit, die Weiteralarmierung zu aktivieren,
                                    sodass der Alarm an Alle weiteralarmiert wird,
                                    wenn nach 30 Sekunden kein Sanitäter im Dienstplan den Alarm angenommen hat.
                                    Die Weiteralarmierung kann ein Administrator in den Einstellungen aktivieren/deaktivieren.
                                </li>
                                <li><b>An Einzelne:</b> Sie können einzelne Personen auswählen, die alarmiert werden sollen.
                                </li>
                            </ul>
                            <b>Sender erhalten NIE Alarme!</b>
                        </div>
                    }
                />
                <AccordionItem
                    summary={
                        <Typography>
                            Einzelne Nutzer werden nicht alarmiert. Was kann ich tun?
                        </Typography>
                    }
                    details={
                        <Typography>
                            Falls ein Alarm an einzelne Personen nicht zugestellt werden kann,
                            befindet sich auf der Alarm-Detailseite unter der Liste der Alarmierten Nutzer eine weitere Liste mit den Nutzern, die nicht alarmiert werden konnten.
                            Für jeden dieser Nutzer wird zusätzlich ein Grund angegeben, weshalb der Alarm nicht zugestellt werden konnte.
                            Diese Informationen können weiterhelfen, um die Alarmierungsprobleme der Nutzer zu beheben.
                        </Typography>
                    }
                />
            </Panel>
            <Panel contentWidth={props.contentWidth}>
                <Typography variant="h3">Sonstiges</Typography>
                <AccordionItem
                    summary={
                        <Typography>
                            Wie erstelle ich ein Diagnoseprotokoll?
                        </Typography>
                    }
                    details={
                        <Typography>
                            Navigieren Sie nachdem das Fehlverhalten aufgetreten ist in der App zu Einstellungen &gt; Datennutzung.
                            Dort finden Sie einen Button "Diagnoseprotokoll teilen".
                            Senden Sie das Protokoll zusammen mit einer Beschreibung des Fehlverhaltens an {CONTACT_EMAIL}
                        </Typography>
                    }
                />
            </Panel>
        </div >
    )
}