import {
    APP_ROUTE_PRIVACY_POLICY,
    APP_ROUTE_DOCUMENTATION,
    ROUTE_DOCUMENTATION,
    ROUTE_HOME,
    ROUTE_IMPRINT,
    ROUTE_PRIVACY_POLICY,
    ROUTE_FAQ,
    ROUTE_PRICING,
    ROUTE_PRIVACY_POLICY_APP,
    ROUTE_TERMS_AND_CONDITIONS,
} from "../res/routes";
import Home from "./Home";
import Documentation from "./Documentation";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import Imprint from "./Imprint";
import PrivacyPolicy from "./PrivacyPolicy";
import FAQ from "./FAQ";
import Pricing from "./Pricing";
import PrivacyPolicyApp from "./PrivacyPolicyApp";

import TermsAndConditions from "./TermsAndConditions";
import BasePage from "./BasePage";
import NotFoundPage from "./NotFound";


interface UnauthenticatedAppProps {
    contentWidth: number,
}

function Website(props: UnauthenticatedAppProps) {
    const routes = [
        {
            path: ROUTE_HOME,
            element: <Home contentWidth={props.contentWidth} />,
        },
        {
            path: APP_ROUTE_DOCUMENTATION,
            element: <Navigate replace to="/documentation" />
        },
        {
            path: ROUTE_DOCUMENTATION,
            element: <Documentation contentWidth={props.contentWidth} />
        },
        {
            path: ROUTE_FAQ,
            element: <FAQ contentWidth={props.contentWidth} />
        },
        {
            path: ROUTE_PRICING,
            element: <Pricing contentWidth={props.contentWidth} />
        },
        {
            path: ROUTE_IMPRINT,
            element: <Imprint contentWidth={props.contentWidth} />
        },
        {
            path: APP_ROUTE_PRIVACY_POLICY,
            element: <Navigate replace to="privacy-policy" />
        },
        {
            path: ROUTE_PRIVACY_POLICY,
            element: <PrivacyPolicy contentWidth={props.contentWidth} />
        },
        {
            path: ROUTE_PRIVACY_POLICY_APP,
            element: <PrivacyPolicyApp contentWidth={props.contentWidth} />
        },
        {
            path: ROUTE_TERMS_AND_CONDITIONS,
            element: <TermsAndConditions contentWidth={props.contentWidth} />
        },
        {
            path: "*",
            element: <NotFoundPage />
        }
    ]

    const page_routes = routes.map((e) => ({
        path: e.path,
        element: <BasePage contentWidth={props.contentWidth}>{e.element}</BasePage>
    }))

    const router = createBrowserRouter(page_routes);

    // TODO: remove Navigate elements and replace with server-side redirect (https://gist.github.com/mjackson/b5748add2795ce7448a366ae8f8ae3bb)
    return (<RouterProvider router={router} />);
}

export default Website;
