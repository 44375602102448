import { ReactNode } from "react";
import { Box, BoxProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export interface PanelProps extends BoxProps {
  children: ReactNode,
  contentWidth: number
}


const useStyles = makeStyles<Theme, PanelProps>((theme) => ({
  box: {
    [theme.breakpoints.up("sm")]: {
      padding: 30,
    },
    padding: 20,
    marginBottom: 30,
  },
  boxContent: (props) => ({
    maxWidth: props.contentWidth,
    marginLeft: "auto",
    marginRight: "auto"
  })
}));

const Panel = (props: PanelProps) => {
  const classes = useStyles(props);
  const {children, contentWidth, ...boxProps} = props;
  return (
    <Box className={classes.box} {...boxProps}>
      <div className={classes.boxContent}>
        {children}
      </div>
    </Box>
  )
};

export default Panel 