import Panel, { PanelProps } from "./Panel"
import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

interface PrimaryPanelProps extends PanelProps { }

const useStyles = makeStyles<Theme>((theme) => ({
  primaryPanel: {
    backgroundColor: theme.palette.primary.light
  },
}));

const PrimaryPanel = (props: PrimaryPanelProps) => {
  const classes = useStyles();
  return (
    <div className={classes.primaryPanel}>
      <Panel {...props} />
    </div>
  )
}

export default PrimaryPanel;