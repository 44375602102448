import {
  AppBar, Toolbar, IconButton, List, ListItem, ListItemText, Button, Drawer
} from "@mui/material";
import {
  Link,
  useLocation,
} from "react-router-dom";
import { ROUTE_DOCUMENTATION, ROUTE_FAQ, ROUTE_HOME, ROUTE_PRICING } from "../res/routes";
import logo_png from "../res/images/sanialarm.png";
import logo_webp from "../res/images/sanialarm.webp";
import { Menu } from "@mui/icons-material";
import { useState } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { ImgWithFallback } from "./ImgWithFallback";
import { WEB_APP_LINK } from "../res/strings";

interface MainMenuProps {
  contentWidth: number;
}

const useStyles = makeStyles<Theme, MainMenuProps>((theme) => ({
  grow: {
    flex: "1 1 auto",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    alignContent: "center",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolBar: (props) => ({
    maxWidth: props.contentWidth + 150,
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    ...theme.mixins.toolbar,
  }),
  drawer: {
    flexShrink: 0,
  },
  drawerItem: {
    textTransform: 'initial'
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function MainMenu(props: MainMenuProps) {
  const classes = useStyles(props);
  const pathname = useLocation().pathname;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: "WebApp", path: WEB_APP_LINK, outside: true },
    { text: "Kosten", path: ROUTE_PRICING, outside: false },
    { text: "FAQ", path: ROUTE_FAQ, outside: false },
    { text: "Dokumentation", path: ROUTE_DOCUMENTATION, outside: false },
  ];

  const drawerMenuItems = [
    { text: "Home", path: ROUTE_HOME, outside: false },
  ].concat(menuItems);

  return (
    <div>
      <AppBar className={classes.appBar} position="fixed" color="secondary">
        <div className={classes.toolBar}>
          <Toolbar>
            <IconButton
              edge="end"
              className={classes.menuButton}
              component={Link}
              to={ROUTE_HOME}
            >
              <ImgWithFallback
                style={{ display: "block" }}
                src={logo_webp}
                fallback={logo_png}
                alt="logo"
                height="35"
                width="35"
              />
            </IconButton>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {menuItems.map((item) =>
                item.outside ? (
                  <Button
                    color="inherit"
                    key={item.path}
                    variant={pathname === item.path ? "outlined" : undefined}
                    className={classes.menuButton}
                    href={item.path}
                  >
                    {item.text}
                  </Button>
                ) : (
                  <Button
                    color="inherit"
                    key={item.path}
                    component={Link}
                    variant={pathname === item.path ? "outlined" : undefined}
                    className={classes.menuButton}
                    to={item.path}
                  >
                    {item.text}
                  </Button>
                )
              )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                color="default"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <Menu />
              </IconButton>
            </div>
          </Toolbar>
        </div>
      </AppBar>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <div>
          <List>
            {drawerMenuItems.map((item) =>
              item.outside ? (
                <ListItem
                  button
                  component={Button}
                  key={item.path}
                  href={item.path}
                  onClick={toggleDrawer(false)}
                  selected={pathname === item.path}
                >
                  <ListItemText primary={item.text} />
                </ListItem>
              ) : (
                <ListItem
                  button
                  className={classes.drawerItem}
                  key={item.path}
                  component={Link}
                  to={item.path}
                  onClick={toggleDrawer(false)}
                  selected={pathname === item.path}
                >
                  <ListItemText primary={item.text} />
                </ListItem>
              )
            )}
          </List>
        </div>
      </Drawer>
    </div>
  );
};
