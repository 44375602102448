import documentationLoginImagePng from "../res/images/documentation_login.png";
import documentationLoginImageWebP from "../res/images/documentation_login.webp";
import documentationAlertCreateImagePng from "../res/images/documentation_alert_create.png";
import documentationAlertCreateImageWebP from "../res/images/documentation_alert_create.webp";
import documentationAlertDetailsImagePng from "../res/images/documentation_alert_details.png";
import documentationAlertDetailsImageWebP from "../res/images/documentation_alert_details.webp";
import documentationUserOverviewImagePng from "../res/images/documentation_user_overview.png";
import documentationUserOverviewImageWebP from "../res/images/documentation_user_overview.webp";
import documentationTimetableDetailsImagePng from "../res/images/documentation_timetable_details.png";
import documentationTimetableDetailsImageWebP from "../res/images/documentation_timetable_details.webp";
import documentationUserAddImagePng from "../res/images/documentation_user_add.png";
import documentationUserAddImageWebP from "../res/images/documentation_user_add.webp";
import { CONTACT_EMAIL, WEB_APP_LINK } from "../res/strings";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Link as ReactLink } from "react-router-dom";
import { Grid, Typography, Link } from "@mui/material";
import { ImgWithFallback } from "../components/ImgWithFallback";
import Panel from "../components/Panel";
import PrimaryPanel from "../components/PrimaryPanel"
import { ROUTE_FAQ } from "../res/routes";
import { ReactNode } from "react";

interface DocumentationProps {
    contentWidth: number;
}

const useStyles = makeStyles<Theme, DocumentationProps>((theme) => ({
    img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%",
    },
}));

interface DocumentationEntry {
    title: string,
    body: ReactNode,
    image?: string,
    fallbackImage?: string,
    alt?: string
};

const data: DocumentationEntry[] = [
    {
        title: "Einloggen",
        body:
            <div><Typography variant="h6">
                Wurden Sie zu einer Gruppe hinzugefügt?
            </Typography>
                <Typography variant="body1" paragraph>
                    Wenn Sie von einem Nutzer der App bereits zu einer Gruppe
                    hinzugefügt wurden, haben Sie eine E-Mail an die eingegebene
                    E-Mail Adresse erhalten. In dieser befindet sich eine Link um
                    ein Passwort zu vergeben.
                    <br />
                    Abschließend können Sie sich mit der E-Mail und dem vergebenen
                    Passwort einfach in der App anmelden.
                </Typography>
                <Typography variant="h6">
                    Haben Sie bereits einen Account?
                </Typography>
                <Typography variant="body1" paragraph>
                    Dann können Sie Ihre Logindaten eingeben um sich einzuloggen.
                </Typography>
                <Typography variant="h6">
                    Haben Sie Ihr Passwort vergessen?
                </Typography>
                <Typography variant="body1">
                    Kein Problem! Wenn Sie auf "Passwort vergessen?" klicken, können
                    Sie sich eine Mail zum Zurücksetzen des Passwortes zusenden lassen.
                </Typography></div>
        ,
        image: documentationLoginImageWebP,
        fallbackImage: documentationLoginImagePng,
        alt: "App Preview"
    },
    {
        title: "Gruppe erstellen",
        body: <div><Typography variant="body1">
            Sie sind noch nicht in einer Gruppe registriert und möchten eine eigene Gruppe eröffnen?
            <br />
            Dann können Sie ganz einfach, kostenlos, eine neue Gruppe
            hinzufügen. Hierfür gehen Sie nach Start der App auf "Gruppe
            erstellen" und geben die notwendigen Details zu Ihrer Person ein.
            Diese sind:
        </Typography>
            <ul>
                <li>Gruppenname</li>
                <li>Ihr Name</li>
                <li>Ihre E-Mail</li>
                <li>Ihr Geschlecht</li>
            </ul>
        </div>,
    },
    {
        title: "Alarmierung",
        body: <div>
            <Typography variant="h6">Alarmdetails</Typography>
            <Typography variant="body1" paragraph>
                Um einen Alarm senden zu können, muss angegeben werden, in
                welchem Raum Hilfe benötigt wird und was im groben passiert ist.
            </Typography>
            <Typography variant="h6">Alarmierungsarten</Typography>
            <Typography variant="body1" paragraph>
                Der Alarm kann in den 3 folgenden Arten versendet werden:
            </Typography>
            <ol>
                <li>
                    <b>An ausgewählte Sanitäter:</b><br />Der Alarm wird
                    ausschließlich an ausgewählte Nutzer gesendet. Wer alarmiert werden soll muss nach Wahl dieser Alarmierungsart aus einer Liste ausgewählt werden.

                </li>
                <li>
                    <b>Nach Dienstplan</b><br /> Der Alarm wird automatisch an die Sanitäter in den aktiven Dienstplänen geschickt. Die Alarmierung erfolgt dann wie folgt
                    <ol>
                        <li>Ein aktiver Dienstplan ist ein Dienstplan der für den aktuellen Tag eingetragen ist und dessen Interval aus Start- und Endzeit die aktuelle Zeit enthält.</li>
                        <li>Alle Nutzer der aktiven Dienstpläne werden alarmiert. Dabei können auch mehrere Dienstpläne aktuell aktiv sein.</li>
                        <li>Falls die Weiteralarmierung aktiv ist, wird ggfs. weiteralarmiert</li>
                    </ol>
                </li>
                <li>
                    <b>An Alle Sanitäter</b><br /> Der Alarm wird an alle Sanitäter der Gruppe gesendet.
                </li>
            </ol>
            <Typography variant="h6">Optional</Typography>
            <Typography variant="body1">
                Es lassen sich weitere optionale Alarmeinstellungen setzen:
            </Typography>
            <ol>
                <li>
                    <b>Alarmierungsgrenze</b><br /> Falls eine bestimmte Anzahl an
                    Sanitätern benötigt wird, kann der Alarm mit einer
                    Obergrenze gesendet werden. Ist diese erreicht, bricht der
                    Alarm automatisch ab und alle Alarmierten, die noch nicht
                    geantwortet haben, werden abgelehnt.
                </li>
                <li>
                    <b>Alarmierung nach Geschlecht</b><br /> Wenn Sanitäter eines
                    bestimmten Geschlechts alarmiert werden sollen, kann hier
                    das entsprechende Geschlecht ausgewählt werden und es werden
                    nur Sanitäter diesen Geschlechts alarmiert
                </li>
            </ol>
        </div>,
        image: documentationAlertCreateImageWebP,
        fallbackImage: documentationAlertCreateImagePng,
        alt: "Preview"
    },
    {
        title: "Live Alarm-Überwachung",
        body: <div>
            <Typography variant="body1" paragraph>
                Nach Start einer Alarmierung kann in dieser Übersicht der Alarm
                mitverfolgt werden.
            </Typography>
            <Typography variant="h6" >Alarmierungsstatus</Typography>
            <Typography variant="body1" paragraph>
                Für alle unter "Alarmierte" aufgelisteten Nutzer wird der aktuelle Alarmierungsstatus angezeigt, der Live aktualisiert wird:
            </Typography>
            <ul>
                <li>
                    <b>Fragezeichen</b><br /> Der Alarm wurde von dem alarmierten
                    noch nicht empfangen oder die erhaltene Alarmierungsbenachrichtigung wurde noch nicht geöffnet
                </li>
                <li>
                    <b>Vibration</b><br /> Der Alarm wurde empfangen und geöffnet, jedoch noch
                    nicht beantwortet
                </li>
                <li>
                    <b>Abgeleht (Kreuz)</b><br /> Der Alarm wurde vom Alarmierten abgeleht
                </li>
                <li>
                    <b>Angenommen (Hacken)</b><br /> Der Alarm wurde vom Alarmierten
                    angenommen
                </li>
            </ul>
            <Typography variant="h3" gutterBottom>
                Weiteralarmierung
            </Typography>
            <Typography variant="body1">
                Diese Option kann unter "Einstellungen" &rt; "Gruppe" &rt; "Weiteralarmierung" aktiviert werden.
                Die Weiteralarmierung wird nach folgenden Ereignissen aktiviert:
                <br />
                Der Alarm wurde nach Dienstplan oder an Alle gesendet und…
            </Typography>
            <ul>
                <li>Alle Sanitäter haben abgelehnt, oder</li>
                <li>
                    30 Sekunden sind vergangen und keiner hat dem Alarm zugesagt
                </li>
            </ul>
            <Typography variant="body1">
                Sobald eines dieser Ereignisse eintritt wird der Alarm
                weitergeleitet, sodass alle übrigen Sanitäter die in der Gruppe
                eingetragen sind auch mit alarmiert werden.
            </Typography>
        </div>,
        image: documentationAlertDetailsImageWebP,
        fallbackImage: documentationAlertDetailsImagePng,
        alt: "App Preview"
    },
    {
        title: "Dienstpläne",
        body: <div>
            <Typography variant="body1" paragraph>
                Möchen Sie die in der Gruppe registrierten Sanitäter in Dienstpläne einteilen, sodass nicht alle Sanitäter alarmiert werden?
                Hierfür bietet die SaniAlarm App die Möglichkeit Dienstpläne zu erstellen und nach diesen zu Alarmieren.
            </Typography>
            <Typography variant="h6" >Dienstplan erstellen</Typography>
            <Typography variant="body1" paragraph>
                Jeder Dienstplan hat folgende Felder, die eingetragen werden müssen:
            </Typography>
            <ul>
                <li><b>Tag</b><br />An welchem Tag der Dienstplan aktiv sein soll</li>
                <li><b>Start- und Endzeit</b><br />Um welche Uhrzeit der Dienstplan am ausgewählten Tag aktiv sein soll. Der Dienstplan ist aktiv, wenn die Uhrzeit nach der Startzeit und vor der Endzeit liegt.</li>
                <li><b>Zuständige Nutzer</b><br />Die Nutzer, vorwiegend Sanitäter, die im Dienstplan eingetragen sein sollen. Wenn nach Dienstplan alarmiert wird, werden diese Nutzer der aktiven Dienstpläne alarmiert.</li>
            </ul>
            <Typography variant="h6" >Nach Dienstplan alarmieren</Typography>
            <Typography variant="body1" paragraph>
                Wenn ein Alarm nach Dienstplan gesendet wird, werden alle Sanitäter der aktiven Dienstpläne alarmiert.
                Weitere Informationen zur Alarmierung nach Dienstplan können Sie unter "Alarmierung" nachlesen.
            </Typography>
            <Typography variant="h6" >Weiteralarmierung</Typography>
            <Typography variant="body1" paragraph>
                Es ist möglich eine automatische Weiteralarmierung zu aktivieren, die bei einem Alarm nach Dienstplan automatisch zusätzliche Nutzer alarmiert, sollte kein Nutzer den Alarm annehmen.
                Weitere Informationen dazu unter "Weiteralarmierung".
            </Typography>
        </div>,
        image: documentationTimetableDetailsImageWebP,
        fallbackImage: documentationTimetableDetailsImagePng,
        alt: "App Preview",
    },
    {
        title: "Nutzerverwaltung",
        body: <div>
            <Typography variant="body1">
                Alle Nutzer werden den entsprechenden 3 Gruppen zugeordnet
            </Typography>
            <Typography variant="h6" >Sanitäter</Typography>
            <Typography variant="body1" paragraph>
                Sanitäter können bei Alarmen alarmiert werden, Dienstpläne
                einsehen, bearbeiten und löschen, sowie Alarme nach allen Alarmierungsarten senden.
                Sanitäter haben keinen Zugriff auf die Übersicht gesendeter Alarme, die Nutzerverwaltung sowie die Gruppeneinstellungen.
            </Typography>
            <Typography variant="h6" >Sender</Typography>
            <Typography variant="body1" paragraph>
                Als Sender ist es einzig erlaubt Alarme zu senden und somit
                perfekt für Lehrer geeignet. Dabei können Sender ausschließlich Alarme an Alle oder nach Dienstplan senden, jedoch nicht an einzelne Nutzer.
            </Typography>
            <Typography variant="h6" >Administratoren</Typography>
            <Typography variant="body1" paragraph>
                Als Administrator hat man vollen Zugriff auf die Gruppe. Man
                kann:
            </Typography>
            <ul>
                <li>Alarme senden</li>
                <li>Dienstpläne verwalten</li>
                <li>Nutzer verwalten</li>
                <li>Alarme mitverfolgen</li>
                <li>Benachrichtigungen über gesendete Alarme empfangen</li>
                <li>Alarmübersicht einsehen</li>
                <li>gesendete Alarme einsehen</li>
                <li>Gruppendetails verwalten</li>
                <li>Gruppe löschen</li>
            </ul>
            <Typography variant="body1" paragraph>
                Da Administratoren vollen Zugriff auf die Gruppe haben, sollten Sie behutsam auswählen, wer Administrator werden soll.
            </Typography>
        </div>,
        image: documentationUserOverviewImageWebP,
        fallbackImage: documentationUserOverviewImagePng,
        alt: "App Preview",
    },
    {
        title: "Geräteverwaltung",
        body: <div>
            <Typography variant="body1">
                Es ist möglich, dass mehrere Geräte bzw. Nutzer über einen
                Account gleichzeitig angemeldet sind.
                <br />
                <br />
                Diese Option finden Sie bei jedem Nutzer in den Nutzerdetails.
                <br />
                Dort kann eingestellt werden, auf wie vielen Geräte der Nutzer maximal
                gleichzeitig angemeldet sein kann. Außerdem sieht man die
                aktuell angemeldete Anzahl an Geräten.
                <br />
                <br />
                Zu beachten ist, dass maximal so viele Geräte angemeldet werden
                können wie in der Gruppe Gerätelizenzen vorhanden sind (weiter Informationen diesbezüglich
                unter Kosten)
            </Typography>
        </div>,
        // image: documentationAlertDetailsImageWebP,
        // fallbackImage: documentationAlertDetailsImagePng,
        image: undefined,
        fallbackImage: undefined,
        alt: "App Preview",
    },
    {
        title: "Nutzer zu Gruppe hinzufügen",
        body: <div>
            <Typography variant="body1">
                Um Nutzer hinzuzufügen müssen lediglich folgende Informationen
                über den Nutzer angegeben werden
            </Typography>
            <ul>
                <li>Name</li>
                <li>Modus (Sanitäter, Sender, Administrator)</li>
                <li>E-Mail</li>
                <li>Geschlecht</li>
            </ul>
        </div>,
        image: documentationUserAddImageWebP,
        fallbackImage: documentationUserAddImagePng,
        alt: "App Preview"
    },
    {
        title: "Nutzer löschen bzw. Gruppe löschen",
        body: <div>
            <Typography variant="h6" >Nutzer löschen</Typography>
            <Typography variant="body1" paragraph>
                Diese Option ist nur für Administratoren zugänglich.
                Nutzer können in der Nutzerübersicht gelöscht werden. Klicken Sie hierfür auf den Nutzer der gelöscht werden soll. In der sich öffnenden Ansicht können Sie mittels "Löschen" den Nutzer löschen.
                Nutzer können sich jedoch nicht selbst aus der Gruppe löschen, somit ist es ausschließlich möglich andere Nutzer zu löschen.
            </Typography>
            <Typography variant="h6" >Gruppe löschen</Typography>
            <Typography variant="body1" paragraph>
                Ausschließlich Administratoren können eine Gruppe löschen.
                Ein Löschen der Gruppe ist entgültig und kann nicht mehr rückgängig gemacht werden.
                Sollten Sie Ihre Gruppe versehentlich gelöscht haben,
                kontaktieren Sie bitte <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>.
                Das Löschen der Gruppe ist über Einstellungen &rt; Gruppe &rt; Gruppe löschen möglich.
                Mit der Gruppe werden alle Alarme, Dienstpläne, Lizenzen, und Nutzer gelöscht.
            </Typography>
        </div>,
        image: documentationUserAddImageWebP,
        fallbackImage: documentationUserAddImagePng,
        alt: "App Preview"
    },
    {
        title: "Kosten",
        body: <div>
            <Typography variant="body1" paragraph>
                Die Nutzung der App ist kostenlos. Jede Gruppe kann bis zu 5
                Geräte registrieren, ohne dass Kosten für die Nutzer anfallen.
                <br />
                Falls mehr als 5 Nutzer benötigt werden, können diese wie folgt
                gekauft werden:
            </Typography>
            <ul>
                <li>
                    <b>Per App</b><br /> In der SaniAlarm Android App, iOS App oder <Link href={WEB_APP_LINK}>WebApp</Link> kann man
                    in den Einstellungen unter Gruppe weitere Gerätelizenzen kaufen. Auch sind dort alle bereits gekauften Gerätelizenzen einsehbar.
                    Der Kauf kann auf Rechnung, per PayPal oder per Kreditkarte erfolgen. Die Gerätelizenzen sind nach Kauf sofort in der Gruppe hinterlegt.
                </li>
                <li>
                    <b>Per E-Mail</b><br />Sollte der Kauf über die App nicht funktionieren,
                    können Sie auch gerne weitere Gerätelizenzen per E-Mail an <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link> anfragen.
                </li>
            </ul>
        </div>
    },
    {
        title: "Fragen?",
        body: <Typography variant="h6">
            Möglicherweiße wird Ihre Frage im Abschnitt <Link component={ReactLink} to={ROUTE_FAQ}>FAQ</Link> beantwortet.<br />
            Andernfalls, senden Sie gerne eine E-Mail an <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>
        </Typography>
    }
]

export default function Documentation(props: DocumentationProps) {
    const classes = useStyles(props);
    return (
        <div>
            {data.map((item, idx) => {
                const image =
                    <Grid key="image" item xs={3}>
                        {(item.image || item.fallbackImage) &&
                            <ImgWithFallback
                                className={classes.img}
                                src={item.image}
                                fallback={item.fallbackImage}
                                alt={item.alt}
                            />
                        }
                    </Grid>

                const body = <Grid key="body" item xs={9}>{item.body}</Grid>

                const inner = (idx % 2 === 0) ? [image, body] : [body, image];

                return <Panel key={idx} contentWidth={props.contentWidth}>
                    <Typography variant="h3" paragraph>{item.title}</Typography>
                    <Grid container justifyContent="center" spacing={3}>
                        {inner}
                    </Grid>
                </Panel>
            })}
        </div>
    );
}
