import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import Website from "./website/Website";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const theme = createTheme({
    palette: {
        primary: {
            main: "#630023",
            light: alpha("#630023", 0.1),
            dark: "#500028",
            contrastText: "#fff",

        },
        secondary: {
            main: "#f5f5f5",
            contrastText: "#630023",
        },
        background: {
            default: "#f5f5f5",
        },
    },
});

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAiNP01pMjDp4VCGApEbFk9yZf_rVkqAoc",
    authDomain: "sanialarm-website.firebaseapp.com",
    projectId: "sanialarm-website",
    storageBucket: "sanialarm-website.appspot.com",
    messagingSenderId: "423780969829",
    appId: "1:423780969829:web:f2af731ff2478c58c7c58c",
    measurementId: "G-GFVWBPWW2Z"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log(analytics.app.name);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Website contentWidth={1200} />
        </ThemeProvider>
    </React.StrictMode>
);
