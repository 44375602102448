import React, { FunctionComponent, ImgHTMLAttributes } from "react";

interface ImgWithFallbackProps extends ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  fallback?: string;
  type?: string;
}

export const ImgWithFallback: FunctionComponent<ImgWithFallbackProps> = ({
  src,
  fallback,
  type = "image/webp",
  alt = "image",
  ...delegated
}) => {
  return (
    <picture>
      <source srcSet={src} type={type} />
      <img src={fallback} {...delegated} alt={alt} />
    </picture>
  );
};
