const SCHEME = window.location.href.split("/")[0];
const DOMAIN = window.location.href.split("/")[2].replace(/^www\./, '');

export const WEB_APP_LINK = `${SCHEME}//web.${DOMAIN}`;
export const CONTACT_EMAIL = "support@sanialarm.de";
export const CONTACT_PHONE_NUMBER = "0160 98417203";
export const ANDROID_STORE_LINK =
  "https://play.google.com/store/apps/details?id=wielandgymnasium.sanialarm&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
export const IOS_STORE_LINK =
  "https://itunes.apple.com/us/app/sanit%C3%A4ter-alarmierung/id1367364096?l=de&ls=1&mt=8";
