export const ROUTE_HOME = "/";
export const ROUTE_FAQ = "/faq";
export const ROUTE_PRICING = "/pricing";
export const ROUTE_DOCUMENTATION = "/documentation";
export const ROUTE_IMPRINT = "/imprint";
export const ROUTE_PRIVACY_POLICY = "/privacy-policy";
export const ROUTE_TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const ROUTE_PRIVACY_POLICY_APP = "/privacy-policy-app";

export const APP_ROUTE_DOCUMENTATION = "/dokumentation";
export const APP_ROUTE_PRIVACY_POLICY = "/datenschutzerklarung";
