import React from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Link } from "@mui/material";
import { CONTACT_EMAIL } from "../res/strings";

interface TermsAndConditionsProps {
    contentWidth: number;
}

const useStyles = makeStyles<Theme, TermsAndConditionsProps>(() => ({
    box: {
        padding: 30,
        marginBottom: 30,
    },
    boxContent: (props) => ({
        maxWidth: props.contentWidth,
        marginLeft: "auto",
        marginRight: "auto",
    }),
}));

export default function TermsAndConditions(props: TermsAndConditionsProps) {
    const classes = useStyles(props);
    return (
        <Box className={classes.box}>
            <div className={classes.boxContent}>
                <Typography variant="h4">Geschäftsbedigungen</Typography>
                <Typography variant="body2" gutterBottom>
                    Durch das Herunterladen oder die Nutzung der App gelten diese Bedingungen automatisch für Sie - Sie sollten daher sicherstellen, dass Sie sie vor der Nutzung der App sorgfältig lesen. Es ist Ihnen nicht gestattet, die App, Teile der App oder unsere Marken in irgendeiner Weise zu kopieren oder zu verändern. Sie dürfen nicht versuchen, den Quellcode der App zu extrahieren, und Sie sollten auch nicht versuchen, die App in andere Sprachen zu übersetzen oder abgeleitete Versionen zu erstellen. Die App selbst und alle damit verbundenen Marken, Urheberrechte, Datenbankrechte und sonstigen geistigen Eigentumsrechte gehören weiterhin SaniAlarm.
                </Typography><Typography variant="body2" gutterBottom>
                    SaniAlarm setzt sich dafür ein, dass die App so nützlich und effizient wie möglich ist. Aus diesem Grund behalten wir uns das Recht vor, jederzeit und ohne Angabe von Gründen Änderungen an der App vorzunehmen oder Gebühren für ihre Dienste zu erheben. Wir werden Ihnen niemals Gebühren für die App oder ihre Dienste in Rechnung stellen, ohne Ihnen genau zu erklären, wofür Sie bezahlen.
                </Typography><Typography variant="body2" gutterBottom>
                    Die SaniAlarm-App speichert und verarbeitet personenbezogene Daten, die Sie uns zur Verfügung gestellt haben, um unseren Service anzubieten. Es liegt in Ihrer Verantwortung, Ihr Telefon und den Zugriff auf die App sicher zu halten. Wir empfehlen Ihnen daher, Ihr Telefon nicht zu jailbreaken oder zu rooten, d.h. die vom offiziellen Betriebssystem Ihres Geräts auferlegten Softwarebeschränkungen und -einschränkungen aufzuheben. Dies könnte Ihr Telefon anfällig für Malware/Viren/Schadprogramme machen, die Sicherheitsfunktionen Ihres Telefons beeinträchtigen und dazu führen, dass die SaniAlarm-App nicht mehr richtig oder überhaupt nicht mehr funktioniert.
                </Typography><Typography variant="body2" gutterBottom>
                    Die App nutzt Dienste von Drittanbietern, die ihre Allgemeinen Geschäftsbedingungen bekannt geben.
                </Typography><Typography variant="body2" gutterBottom>
                    Link zu den Allgemeinen Geschäftsbedingungen der von der App genutzten Drittanbieter:
                </Typography>
                    <ul>
                        <li><Link href="https://policies.google.com/terms">Google Play-Dienste</Link></li>
                        <li><Link href="https://firebase.google.com/terms/analytics">Google Analytics für Firebase</Link></li>
                        <li><Link href="https://firebase.google.com/terms/crashlytics">Firebase Crashlytics</Link></li>
                    </ul>
                <Typography variant="body2" gutterBottom>
                    Sie sollten sich darüber im Klaren sein, dass SaniAlarm für bestimmte Dinge keine Verantwortung übernehmen kann. Für bestimmte Funktionen der App muss eine aktive Internetverbindung bestehen. SaniAlarm kann jedoch keine Verantwortung dafür übernehmen, dass die App nicht voll funktionsfähig ist, wenn Sie keinen Zugang zu WLAN haben und Ihr Datenvolumen erschöpft ist.
                </Typography><Typography variant="body2" gutterBottom>
                    Wenn Sie die App außerhalb eines Gebiets mit Wi-Fi nutzen, sollten Sie daran denken, dass die Bedingungen des Vertrags mit Ihrem Mobilfunkanbieter weiterhin gelten. Daher kann es sein, dass Ihnen Ihr Mobilfunkanbieter die Datenkosten für die Dauer der Verbindung während des Zugriffs auf die App oder andere Kosten von Dritten in Rechnung stellt. Mit der Nutzung der App übernehmen Sie die Verantwortung für solche Gebühren, einschließlich Roaming-Gebühren, wenn Sie die App außerhalb Ihres Heimatgebiets (d.h. Ihrer Region oder Ihres Landes) nutzen, ohne das Daten-Roaming zu deaktivieren. Wenn Sie nicht der Rechnungszahler für das Gerät sind, auf dem Sie die App verwenden, beachten Sie bitte, dass wir davon ausgehen, dass Sie die Erlaubnis des Rechnungszahlers für die Nutzung der App erhalten haben.
                </Typography><Typography variant="body2" gutterBottom>
                    Ebenso kann SaniAlarm nicht immer die Verantwortung für die Art und Weise übernehmen, in der Sie die App nutzen, d.h. Sie müssen sicherstellen, dass Ihr Gerät die Erlaubnis zur Nutzung der App hat.
                </Typography>
                <Typography variant="h6">Kontaktieren Sie uns</Typography>
                <Typography variant="body2" >
                    Wenn Sie Fragen oder Anregungen zu unserer Datenschutzpolitik haben, zögern Sie nicht, uns unter <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link> zu kontaktieren.
                </Typography>
            </div>
        </Box >
    );
}